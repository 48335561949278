import { ApolloQueryResult, gql } from '@apollo/client';
import { TaskStatus, User } from '../../../../generated/graphql';

// TODO:
// 1. Load the GraphQL Query from a .graphql file. There's a babel plugin.
// 2. Figure out how to auto-generate the types from our GraphQL Schema.

export const QUERY = gql`
  query getReferral($id: ID!, $organizationId: ID) {
    getReferral(id: $id) {
      id
      description
      result
      patientIsPreAuthorized
      status
      insertedAt
      nextStatuses
      patient {
        id
        identifier
        address1
        address2
        city
        state
        postalCode
        dob
        email
        familyName
        givenName1
        givenName2
        givenName3
        phone
        sex
        preferredLanguageCode
      }
      tasks {
        id
        status
        viewerIsOwner
        startedAt
        finishedAt
        insertedAt
        user {
          id
          name
        }
        workflowQueue {
          id
          name
          color
        }
      }
      patientReferral {
        id
        patientEmail
        profile {
          id
          displayName
          phone
          profileImgUrl
          location {
            id
            name
            address1
            address2
            city
            state
            postalCode
            distanceFromPatient
          }
          organization {
            id
            name
          }
        }
      }
      profileReferral {
        id
        profile {
          id
          displayName
          phone
          profileImgUrl
          isAccessCenter
          location {
            id
            name
            address1
            address2
            city
            state
            postalCode
            distanceFromPatient
          }
          organization {
            id
            name
          }
        }
      }
      coverage {
        id
        network
        groupId
        planName
        planId
      }
      provider {
        id
        name
        organization {
          id
          name
        }
        location {
          id
          name
          address1
          address2
          city
          state
          postalCode
        }
      }
      createdByUser {
        id
        name
        email
      }
      appointment {
        id
        status
        start
        end
        rescheduledTo {
          id
          start
        }
        rescheduledFrom {
          id
          start
        }
        rescheduleLink
        profile {
          id
          displayName
          generalPatientInstructions
          phone
          type
          profileImgUrl
          location {
            id
            name
            address1
            address2
            city
            state
            postalCode
            distanceFromPatient
          }
          organization {
            id
            name
          }
        }
      }
      procedure {
        id
        code
        display
        isUnspecified
        name
        specialty {
          id
          name
        }
      }
      diagnoses {
        id
        code
        description
      }
      referralOrder {
        id
        type
        displayFilename
        orderUrl
      }
      tags(organizationId: $organizationId) {
        key
        value
      }
      questionnaireAnswerSets {
        id
        questionnaireName
        questionnaireAnswers {
          id
          text
          value
        }
      }
    }
  }
`;

export interface ProcedureData {
  id: string;
  code: string;
  display: string;
  isUnspecified: boolean;
  name: string;
  specialty: {
    id: string;
    name: string;
  };
}

export interface DiagnosesData {
  id: string;
  code: string;
  description: string;
}

export interface AppointmentData {
  id: string;
  status: string;
  start: string;
  end: string;
  procedure: ProcedureData;
  profile: ProfileData;
  rescheduleLink: string;
  rescheduledTo?: {
    id: string;
    start: string;
  };
  rescheduledFrom?: {
    id: string;
    start: string;
  };
}

export interface ProfileData {
  id: string;
  isActive?: boolean;
  displayName: string;
  generalPatientInstructions: string;
  phone: string;
  type: string;
  isAccessCenter: boolean;
  profileImgUrl: string;
  location: LocationData;
  profileProcedures?: {
    id: string;
    procedure?: {
      id: string;
      name: string;
      specialty: {
        id: string;
        name: string;
      };
    };
  }[];
  organization: {
    id: string;
    name: string;
  };
}

export interface PatientData {
  id: string;
  identifier: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  dob: string | null;
  email: string | null;
  familyName: string | null;
  givenName1: string | null;
  givenName2: string | null;
  givenName3: string | null;
  phone: string | null;
  sex: string | null;
  preferredLanguageCode: string | null;
}

export interface PatientReferral {
  id: string;
  appointmentId: string | null;
  patientEmail: string | null;
  profile: ProfileData;
}

export interface ProfileReferral {
  id: string;
  profile: ProfileData;
}

export interface CoverageData {
  id: string | null;
  network: string | null;
  groupId: string | null;
  planName: string | null;
  planId: string | null;
}

export interface ProviderData {
  id: string;
  name: string;
  location: LocationData;
  organization: {
    id: string;
    name: string;
  };
}

export interface UserData {
  id: string;
  name: string;
  email: string;
}

export interface LocationData {
  id: string;
  name: string;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  distanceFromPatient: number | null;
}

export interface QuestionnaireAnswerSet {
  id: string;
  questionnaireName: string | '';
  questionnaireAnswers: QuestionnaireAnswer[] | [];
}

export interface QuestionnaireAnswer {
  id: string;
  text: string;
  value: string | '';
}

export interface ReferralData {
  id: string;
  description: string;
  result: string;
  status: string;
  insertedAt: string;
  patientIsPreAuthorized: boolean;
  patient: PatientData;
  patientReferral: PatientReferral | null;
  profileReferral: ProfileReferral | null;
  coverage: CoverageData;
  provider: ProviderData;
  createdByUser: UserData;
  appointment?: AppointmentData;
  procedure: ProcedureData;
  diagnoses: DiagnosesData[];
  referralOrder: ReferralOrderData[];
  nextStatuses: [];
  tasks: {
    id: string;
    status: TaskStatus;
    viewerIsOwner: boolean;
    startedAt?: string;
    finishedAt?: string;
    insertedAt: string;
    user?: User;
    workflowQueue: {
      id: string;
      name: string;
      color: string | null;
    };
  }[];
  tags: {
    key: string;
    value: string | null;
  }[];
  questionnaireAnswerSets: QuestionnaireAnswerSet[] | [];
}

export interface ReferralOrderData {
  id: string;
  type: string;
  displayFilename: string;
  orderUrl: string;
}

export interface ReferralDocumentData {
  referralId: string;
  type: string;
  displayFilename: string;
  orderUrl: string;
  documentUrl: string;
}

export interface GetReferralQueryData {
  getReferral?: ReferralData;
}

export interface GetReferralQueryVariables {
  id: string;
  organizationId: string;
}

export type GetReferralQueryRefetch = (
  variables?: GetReferralQueryVariables | undefined
) => Promise<ApolloQueryResult<GetReferralQueryData>>;
