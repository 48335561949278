import { createLink } from 'apollo-absinthe-upload-link';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache, NormalizedCacheObject, ApolloLink, ApolloClient } from '@apollo/client';
import config from './config';
import { getLoginToken, removeLoginToken } from './modules/auth';

const apolloUploadLink = createLink({ uri: config.endpoint + '/graphql' });

const authLink = setContext((_, { headers }) => {
  const token = getLoginToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export function makeClient(on401: () => void): ApolloClient<NormalizedCacheObject> {
  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (err.message.replace('GraphQL error: ', '') === 'Unauthorized') {
          removeLoginToken();
          console.log('Token expired or unauthorized, redirecting to login');
          on401();
        } else if (process.env.NODE_ENV === 'development') {
          console.error(
            `[GraphQL error]: Message: ${err.message}, Location: ${JSON.stringify(err.locations, null, 2)}, Path: ${
              err.path
            }`
          );
        }
      }
    }

    if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
      removeLoginToken();
      console.log('Network error: Unauthorized, redirecting to login');
      on401();
    }
  });

  const cache = new InMemoryCache({
    resultCacheMaxSize: Math.pow(2, 32),
    typePolicies: {
      Viewer: {
        fields: {
          organizations: {
            merge(existing, incoming) {
              const mergedMap = new Map();
              existing?.forEach((item: { __ref: string }) => mergedMap.set(item.__ref, item));
              incoming?.forEach((item: { __ref: string }) => mergedMap.set(item.__ref, item));
              return Array.from(mergedMap.values());
            },
          },
        },
      },
    },
  });

  const client = new ApolloClient<NormalizedCacheObject>({
    cache: cache,
    link: ApolloLink.from([errorLink, authLink, apolloUploadLink]),
  });

  return client;
}
