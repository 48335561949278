import { Button, message, Switch } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { SlotsInterface } from './SlotsContainer';
import { useViewSlotsContainerContext } from './ViewSlotsContainer';
import { CreateBlackoutDate, ListBlackoutDates, DeleteBlackoutDate } from '../../../../GraphQL';
import { useMutation, useQuery } from '@apollo/client';
import { ApolloError } from '@apollo/client';
const Styles = styled.div`
  .dash-btn {
    cursor: auto;
  }

  .time-btn {
    color: black;
    font-weight: 800;
    :hover: {
      cursor: auto;
    }
    margin-bottom: 0 !important;
  }
`;

const EmptyButton: FC = (): JSX.Element => (
  <Button block className="time-btn dash-btn">
    —
  </Button>
);

interface Props {
  times: SlotsInterface[];
  scheduleId: string;
  refetchSlots: () => void;
  showToggle: boolean;
}

interface Slot {
  __typename?: string;
  id: string;
  start: string;
  end: string;
  status: string;
  blackoutDateId?: string;
}

export interface BlackoutDate {
  id: string;
  start: string;
  end: string;
  startTime: string;
  endTime: string;
  reason: string;
}

function createBackFilledTimes(times: SlotsInterface[], blackoutDates: BlackoutDate[]): any[] {
  const currentDate = moment(times[0].start);
  // Filter blackout dates for the current day
  const filteredBlackoutDates = blackoutDates.filter(blackout => {
    return currentDate.isSame(moment(blackout.start), 'day');
  });

  // Map blackout dates to slots
  const blackoutSlots: Slot[] = filteredBlackoutDates.map(blackout => {
    const blackoutStart = moment(`${currentDate.format('YYYY-MM-DD')} ${blackout.startTime}`);
    const blackoutEnd = moment(`${currentDate.format('YYYY-MM-DD')} ${blackout.endTime}`);

    return {
      id: `blackout-${blackout.id}-${blackoutStart.format('HHmmss')}`,
      start: `${currentDate.format('YYYY-MM-DD')}T${blackoutStart.format('HH:mm:ss')}`,
      end: `${currentDate.format('YYYY-MM-DD')}T${blackoutEnd.format('HH:mm:ss')}`,
      status: 'blackout',
      blackoutDateId: blackout.id,
    };
  });

  // Combine and sort times
  const combinedTimes = [...times, ...blackoutSlots];
  const uniqueTimes = _.uniqBy(combinedTimes, slot => slot.start);

  return uniqueTimes.sort((a, b) => moment(a.start).valueOf() - moment(b.start).valueOf());
}

export const TimeSlots: FC<Props> = ({ times, scheduleId, refetchSlots, showToggle }): JSX.Element => {
  const { maxTimes, setMaxTimes } = useViewSlotsContainerContext();
  const [showMore, setShowMore] = useState(false);
  const [loadingSlots, setLoadingSlots] = useState<{ [key: string]: boolean }>({});

  const { data: blackoutData, refetch: refetchBlackoutDates } = useQuery<{ ListBlackoutDates: BlackoutDate[] }>(
    ListBlackoutDates,
    {
      variables: {
        ListBlackoutDatesInput: {
          scheduleId: scheduleId,
          reason: 'APPOINTMENT',
        },
      },
    }
  );

  const blackoutDates = blackoutData?.ListBlackoutDates || [];
  const backFilledTimes =
    times.length > 0 && blackoutDates ? createBackFilledTimes(times, blackoutDates) : (times as Slot[]);

  const [createBlackoutDate] = useMutation(CreateBlackoutDate);
  const [deleteBlackoutDate] = useMutation(DeleteBlackoutDate);

  const onToggle = async (slot: Slot) => {
    try {
      setLoadingSlots({ ...loadingSlots, [slot.id]: true });
      if (slot.status === 'blackout') {
        await deleteBlackoutDate({
          variables: {
            DeleteBlackoutDateInput: {
              id: slot.blackoutDateId,
              scheduleId,
            },
          },
        });
        // the order of fetching matters here. Otherwise the UI will have a delay in updating the slots which will cause flickering
        await refetchSlots();
        await refetchBlackoutDates();
      } else {
        const startTime = moment(slot.start).format('HH:mm:ss');
        const endTime = moment(slot.end).format('HH:mm:ss');
        const startDate = moment(slot.start).format('YYYY-MM-DD');
        const endDate = moment(slot.end).format('YYYY-MM-DD');

        await createBlackoutDate({
          variables: {
            CreateBlackoutDateInput: {
              start: startDate,
              end: endDate,
              startTime,
              endTime,
              scheduleId,
              reason: 'APPOINTMENT',
            },
          },
        });
        // the order of fetching matters here. Otherwise the UI will have a delay in updating the slots which will cause flickering
        await refetchBlackoutDates();
        await refetchSlots();
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        message.error(error.message.replace(/GraphQL error: /g, ''));
      }
      console.error('Error toggling blackout date:', error);
    } finally {
      setLoadingSlots({ ...loadingSlots, [slot.id]: false });
    }
  };

  return (
    <Styles>
      {_.fill(Array(maxTimes), 0).map((_item, index) => {
        if (backFilledTimes[index]) {
          const slot = backFilledTimes[index];
          const isLoading = loadingSlots[slot.id] || false;
          return (
            <div className="inline-flex items-center mb-2" key={index}>
              <div className="w-[3.5rem]">
                <Button type="link" className="time-btn py-1 px-2 w-full text-right">
                  {moment(backFilledTimes[index].start).format('h:mma')}
                </Button>
              </div>
              {showToggle && (
                <div className="ml-1">
                  <Switch
                    size="small"
                    checked={slot.status !== 'blackout'}
                    onClick={checked => onToggle(slot)}
                    key={index}
                    className="bg-gray-300 m-0 p-0"
                    loading={isLoading}
                  />
                </div>
              )}
            </div>
          );
        } else {
          return <EmptyButton key={index} />;
        }
      })}
      {backFilledTimes.length > maxTimes && !showMore ? (
        <Button
          block
          type="link"
          key="show-more"
          className="time-btn"
          onClick={() => {
            setShowMore(true);
            setMaxTimes(backFilledTimes.length);
          }}
        >
          Show More
        </Button>
      ) : null}
    </Styles>
  );
};
