import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

export const questionnaireSaveAsPDF = (prefix?: string, id?: string): void => {
  const element = document.getElementById('questionnaire-answers');
  const button = document.querySelector('#questionnaire-answers button') as HTMLElement;

  if (element && button) {
    button.style.display = 'none';

    html2canvas(element, {
      useCORS: true,
      logging: false,
      width: element.offsetWidth,
      height: element.offsetHeight,
      windowWidth: element.scrollWidth,
      windowHeight: element.scrollHeight,
    }).then(canvas => {
      const imgData = canvas.toDataURL('image/png', 1.0);
      const pdf = new jspdf('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);

      const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      let heightLeft = pdfHeight;
      let position = 0;

      while (heightLeft >= 0) {
        pdf.addImage(imgData, 'PNG', 10, position, pdfWidth, pdfHeight);
        heightLeft -= pdf.internal.pageSize.getHeight();

        if (heightLeft >= 0) {
          pdf.addPage();
          position -= pdf.internal.pageSize.getHeight();
        }
      }

      const fileName = prefix && id ? `${prefix}_${id}_QuestionnaireAnswers.pdf` : 'QuestionnaireAnswers.pdf';
      pdf.save(fileName);

      button.style.display = 'block';
    });
  }
};
