import { Avatar, Button, Card, Col, Divider, Icon, Row, Tag, Typography } from 'antd';
import React, { FC, Fragment, useState } from 'react';
import { useAppContext } from '../../../../hooks';
import { FeatureFlag, FeatureGate, formatEmail, formatPhone, formatTitleCase } from '../../../../helpers';
import * as ptFormat from '../../../../helpers/patients.helper';
import { CoverageData, PatientData } from './GetReferralQuery';
import styled from 'styled-components';
import AddEditPatientForm from './AddEditPatientForm';
import AddPatientCoverageForm from './AddPatientCoverageForm';
import { useGetOrganizationQuery } from '../../../../hooks/queries/useGetOrganizationQuery';
import { isAdminOrBlockitAdmin } from '../../../core/components/PrivateRoute';

const { Text, Title } = Typography;

interface ReferralTags {
  key: string | null;
  value: string | null;
}

interface PatientCardProps {
  patient: PatientData;
  coverage: CoverageData;
  isPreauthorized: boolean;
  tags?: ReferralTags[];
}

const Styles = styled.div`
  .ant-card-meta-title {
    white-space: normal;
  }

  .sub-text {
    font-size: 14px;
  }
`;

const PatientCard: FC<PatientCardProps> = ({ patient, coverage, isPreauthorized, tags = [] }): JSX.Element => {
  const [showPatientForm, setShowPatientForm] = useState(false);
  const [showPatientCoverageForm, setShowPatientCoverageForm] = useState(false);
  const [patientState, setPatientState] = useState(patient);
  const [patientCoverageState, setPatientCoverageState] = useState(coverage);
  const [showEditButton, setShowEditButtonState] = useState(true);

  const { currentOrganization } = useAppContext();
  const isUserAdmin = isAdminOrBlockitAdmin(currentOrganization);
  const { data } = useGetOrganizationQuery({ variables: { organizationId: currentOrganization!.id } }); // eslint-disable-line @typescript-eslint/no-non-null-assertion
  const organization = data?.getOrganization;

  const showMRN = patientState.identifier && patientState.identifier.length < 36;
  const showLanguage = patientState.preferredLanguageCode && patientState.preferredLanguageCode.length > 0;

  const handleHidePatientForm = (): void => setShowPatientForm(false);
  const handleShowPatientForm = (): void => setShowPatientForm(true);
  const handleHidePatientCoverageForm = (): void => setShowPatientCoverageForm(false);
  const handleShowPatientCoverageForm = (): void => setShowPatientCoverageForm(true);
  const handleUpdatePatient = (patient: PatientData): void => setPatientState(patient);
  const handleCreatePatientCoverage = (coverage: CoverageData): void => setPatientCoverageState(coverage);
  const handleShowEditButton = (): void => setShowEditButtonState(false);

  return (
    <Styles>
      <Card>
        <Card.Meta
          avatar={<Avatar size="large" shape="square" icon="user" />}
          title={
            <>
              {ptFormat.formatName(patientState)}{' '}
              {showMRN && (
                <FeatureGate feature={FeatureFlag.ShowMRN}>
                  <div className="sub-text">MRN: {patientState.identifier}</div>
                </FeatureGate>
              )}
            </>
          }
        />
        <Row>
          <Col span={24}>
            <ul style={{ marginTop: '1em' }}>
              <FactIcon type="calendar" text={ptFormat.formatDob(patientState.dob)} />
              <SexFactIcon sex={patientState.sex} />
            </ul>

            <ul>
              <FactIcon type="phone" text={formatPhone(patientState.phone) || 'Unknown Phone'} />
              <FactIcon type="mail" text={formatEmail(patientState.email) || 'Unknown Email'} />

              {showLanguage && (
                <FactIcon type="message" text={patientState.preferredLanguageCode || 'Unknown Language Preference'} />
              )}
            </ul>

            <PatientAddress
              address1={patientState.address1}
              address2={patientState.address2}
              city={patientState.city}
              state={patientState.state}
              postalCode={patientState.postalCode}
            />

            <FeatureGate feature={[FeatureFlag.ManuallyAddPatient, FeatureFlag.EditPatient]}>
              <div>
                <br />
                <Button icon="edit" type="primary" size="small" onClick={handleShowPatientForm}>
                  Edit
                </Button>
                <AddEditPatientForm
                  organization={organization}
                  patient={patientState}
                  show={showPatientForm}
                  handleHidePatientForm={handleHidePatientForm}
                  handleUpdatePatient={handleUpdatePatient}
                />
              </div>
            </FeatureGate>

            <Divider />

            <PatientInsurance
              networkName={patientCoverageState.network}
              planName={patientCoverageState.planName}
              groupId={patientCoverageState.groupId}
              policyId={patientCoverageState.planId}
              isPreauthorized={isPreauthorized}
              tags={tags}
            />
            <>
              {showEditButton && isUserAdmin && isCoverageEmpty(coverage) && (
                <FeatureGate feature={[FeatureFlag.EditPatientCoverage]}>
                  <div>
                    <br />
                    <div>
                      <Button icon="edit" type="primary" size="small" onClick={handleShowPatientCoverageForm}>
                        Add
                      </Button>
                      <AddPatientCoverageForm
                        organization={organization}
                        patient={patientState}
                        coverage={patientCoverageState}
                        show={showPatientCoverageForm}
                        handleHidePatientCoverageForm={handleHidePatientCoverageForm}
                        handleCreatePatientCoverage={handleCreatePatientCoverage}
                        handleShowEditButton={handleShowEditButton}
                      />
                    </div>
                  </div>
                </FeatureGate>
              )}
            </>
          </Col>
        </Row>
      </Card>
    </Styles>
  );
};

export const SexFactIcon: FC<{ sex: string | null }> = ({ sex }): JSX.Element => {
  let type = 'user';
  let text = 'Unknown Sex';
  let firstLetter = 'u';

  if (sex) {
    firstLetter = sex.toLowerCase().charAt(0);
  }

  switch (firstLetter) {
    case 'm':
      type = 'man';
      text = 'Male';
      break;
    case 'f':
      type = 'woman';
      text = 'Female';
      break;
    default:
      type = 'user';
      text = 'Unknown Sex';
  }

  return <FactIcon type={type} text={text} />;
};

export const FactIcon: FC<{ type: string; text: string | null }> = ({ type, text }): JSX.Element => (
  <Row gutter={20}>
    <Col span={2}>
      <Icon type={type} />
    </Col>
    <Col span={12}>
      <Text>{text}</Text>
    </Col>
  </Row>
);

interface PatientAddressProps {
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
}

export const PatientAddress: FC<PatientAddressProps> = ({
  address1 = 'Unknown Street',
  address2 = '',
  city = 'Unknown',
  state = 'Unknown',
  postalCode = '',
}): JSX.Element => {
  return (
    <Row gutter={20}>
      <Col span={2}>
        <Icon type="home" />
      </Col>
      <Col span={12}>
        <ul>
          <li>{formatTitleCase(address1) || 'Unknown'}</li>
          <li>{address2 || ''}</li>
          <li>{`${city ? `${formatTitleCase(city)},` : ''} ${state || ''} ${postalCode || ''}`}</li>
        </ul>
      </Col>
    </Row>
  );
};

interface PatientInsuranceProps {
  networkName: string | null;
  planName: string | null;
  groupId: string | null;
  policyId: string | null;
  isPreauthorized: boolean;
  tags: any;
}

const PatientInsurance: FC<PatientInsuranceProps> = ({
  networkName,
  planName,
  groupId,
  policyId,
  isPreauthorized = false,
  tags,
}): JSX.Element => {
  const isNonEmptyValue = (val: string | null | undefined): boolean => {
    if (!val) return false;
    if (val.trim().length === 0) return false;
    return true;
  };

  const { tagNetworkName, tagGroupId, tagPlanName, tagPolicyId } = parseInsuranceTags(tags);

  const insuranceTitle = (
    <Row type="flex" justify="space-between">
      <Col md={24} lg={8}>
        <Title level={4}>Insurance</Title>
      </Col>
      <Col md={0} lg={2} />
      <Col md={24} lg={14}>
        <IsPreauthorizedTag isPreauthorized={isPreauthorized} />
      </Col>
    </Row>
  );

  const emptyPatientInsurance = (
    <span>Plan information unavailable. The sender did not provide insurance information for this patient.</span>
  );

  const insuranceFields = [
    { title: 'Network', value: tagNetworkName || networkName },
    { title: 'Plan', value: tagPlanName || planName },
    { title: 'Group ID', value: tagGroupId || groupId },
    { title: 'Policy ID', value: tagPolicyId || policyId },
  ];

  const isNonEmptyString = (val: unknown): boolean => typeof val === 'string' && val.trim().length > 0;

  const hasInsuranceData = insuranceFields.some(f => isNonEmptyString(f.value));

  const filledPatientInsurance = (
    <Row type="flex">
      <Col span={18}>
        <ul>
          {insuranceFields
            .filter(f => isNonEmptyValue(f.value))
            .map(f => (
              <li key={f.title}>
                <Text strong>{f.title}:</Text> {f.value}
              </li>
            ))}
        </ul>
        {(!groupId || !policyId) && 'Some plan details were not provided.'}
      </Col>
    </Row>
  );

  return (
    <>
      {insuranceTitle}
      {hasInsuranceData ? filledPatientInsurance : emptyPatientInsurance}
    </>
  );
};

const IsPreauthorizedTag: FC<{ isPreauthorized: boolean | null }> = ({ isPreauthorized = false }): JSX.Element => {
  return isPreauthorized ? (
    <Tag color="green">
      <Icon type="check" />
      <span>&nbsp;Preauthorized</span>
    </Tag>
  ) : (
    <></>
    // <Tag color="gold">
    //   <Icon type="warning" />
    //   <span>&nbsp;Not Preauthorized</span>
    // </Tag>
  );
};

function isCoverageEmpty(obj: CoverageData): boolean {
  const keys = Object.keys(obj);
  return keys
    .filter(key => key !== '__typename')
    .every(key => {
      const value = obj[key as keyof CoverageData];
      return value === null || value === '';
    });
}

function parseInsuranceTags(tagsArray: ReferralTags[]) {
  let insuranceCarrier: string | null = null;
  let insuranceGroupId: string | null = null;
  let insurancePlan: string | null = null;
  let policyId: string | null = null;

  tagsArray.forEach(t => {
    switch (t.key) {
      case 'insurance_carrier':
        insuranceCarrier = t.value;
        break;
      case 'insurance_group_id':
        insuranceGroupId = t.value;
        break;
      case 'insurance_plan':
        insurancePlan = t.value;
        break;
      case 'policy_id':
        policyId = t.value;
        break;
      default:
        break;
    }
  });

  return {
    tagNetworkName: insuranceCarrier,
    tagGroupId: insuranceGroupId,
    tagPlanName: insurancePlan,
    tagPolicyId: policyId,
  };
}

export default PatientCard;
