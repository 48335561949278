import { gql } from '@apollo/client';
import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';
import { Viewer } from '../../modules/viewer';

export const viewerQuery = gql`
  query Viewer($limit: Int) {
    viewer(limit: $limit) {
      id
      name
      email
      blockitAdmin
      organizations {
        id
        name
        slug
        role
        isActive
        isFree
        isCovid
        hasSso
        hasSsoStrict
        useJumpq
        region {
          id
          name
        }
        locations {
          id
          name
          sends
          receives
        }
        featureFlags {
          id
          name
        }
        freePlanConfiguration {
          id
          name
          appointmentsPerWeek
          locations
          profiles
          providers
          users
        }
        cancelReasons {
          key
          value
        }
      }
    }
  }
`;

interface Data {
  viewer: Viewer;
}

type Options = QueryHookOptions<Data>;

type Result = QueryResult<Data>;

export const useViewerQuery = (options: Options): Result => {
  return useQuery<Data>(viewerQuery, options);
};
